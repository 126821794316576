import {Resource, ResourceTypes} from './resource';
import {JobTypes, JsonResource, ToolmakerAPIClient} from "../index";

interface ResizeTransformation {
  width?: number,
  height?: number,
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside',
  position?: 'centre' | 'top' | 'right top' | 'right' | 'right bottom' | 'bottom' | 'left bottom' | 'left' | 'left top',
  background?: string | {
    r: number,
    g: number,
    b: number,
    alpha: number
  },
  kernel?: 'nearest' | 'linear' | 'cubic' | 'mitchell' | 'lanczos2' | 'lanczos3',
  withoutEnlargement?: boolean,
  withoutReduction?: boolean,
  fastShrinkOnLoad?: boolean,
}

interface ExtendTransformation {
  top?: number,
  left?: number,
  bottom?: number,
  right?: number,
  extendWith?: 'background' | 'copy' | 'repeat' | 'mirror',
  background?: string | {
    r: number,
    g: number,
    b: number,
    alpha: number
  },
}

interface ExtractTransformation {
  left?: number,
  top?: number,
  width?: number,
  height?: number,
}

interface TrimTransformation {
  background?: string | {
    r: number,
    g: number,
    b: number,
    alpha: number
  },
  threshold?: number,
  lineArt?: boolean,
}

interface ClipTransformation {
  width?: number,
  height?: number,
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside',
}

class ImageResource extends Resource {
  constructor(readonly id: string, readonly type: ResourceTypes.IMAGE = ResourceTypes.IMAGE) {
    super(id, type);
  }

  private steps : any[] = [];

  resize(parameters: ResizeTransformation) {
    this.steps.push({
      type: 'resize',
      parameters
    });

    return this;
  }

  extend(parameters: ExtendTransformation) {
    this.steps.push({
      type: 'extend',
      parameters
    });

    return this;
  }

  extract(parameters: ExtractTransformation) {
    this.steps.push({
      type: 'extract',
      parameters
    });

    return this;
  }

  trim(parameters: TrimTransformation) {
    this.steps.push({
      type: 'trim',
      parameters
    });

    return this;
  }

  async clip(userId: string, parameters: ClipTransformation) {
    const metadata = await this.metadata(userId);
    if (metadata.width === undefined || metadata.height === undefined) {
      throw new Error('Cannot clip image without width and height metadata');
    }

    if (parameters.width === undefined && parameters.height === undefined) {
      throw new Error('Cannot clip image without width or height parameters');
    }

    if (metadata.width > (parameters.width === undefined ? Number.MAX_VALUE : parameters.width) ||
      metadata.height > (parameters.height === undefined ? Number.MAX_VALUE : parameters.height) ||
      parameters.fit === 'fill' || parameters.fit === 'inside'
    ) {
      this.resize({
        width: parameters.width,
        height: parameters.height,
        fit: parameters.fit,
        position: 'centre',
        background: 'white'
      })
    }

    return this;
  }

  async process(userId?: string, steps?: any[]) {
    const response : {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.PROCESS_IMAGE, {
      image: this.id,
      process: steps || this.steps
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.image as string, userId);
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return resource;
  }

  async composite(userId: string, overlays: {
    image: ImageResource,
    blend?: string,
    top?: number,
    left?: number
  }[]) {
    const response : {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.COMPOSITE_IMAGE, {
      base: this.id,
      overlays: overlays.map(overlay => ({
        image: overlay.image.id,
        blend: overlay.blend,
        top: overlay.top,
        left: overlay.left
      }))
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.image as string, userId) as ImageResource | null;
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return resource;
  }

  async binarize(userId: string, threshold: number = 200, kernelSize: number = 1) {
    const response : {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.BINARIZE_IMAGE, {
      image: this.id,
      threshold,
      kernelSize
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.image as string, userId) as ImageResource | null;
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return resource;
  }

  async extractFaceMask(userId: string, invert: boolean = false) {
    const response: {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.EXTRACT_FACE_MASK_FROM_IMAGE, {
      image: this.id,
      invertMask: invert
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.image as string, userId) as ImageResource | null;
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return {
      mask: resource,
      whitePixels: parseFloat(response.outputs.whitePixels as string),
      ratio: parseFloat(response.outputs.ratio as string)
    };
  }

  async extractColorPalette(userId: string, colors: number = 5) {
    const response: {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.EXTRACT_COLOR_PALETTE, {
      image: this.id,
      numColors: colors
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.palette as string, userId) as JsonResource | null;
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return resource;
  }

  async convertToSVG(userId: string, centerline: boolean = false) {
    const response: {
      id: string,
      type: string,
      outputs: Record<string, string>
    } = await ToolmakerAPIClient.instance.createJob(JobTypes.CONVERT_IMAGE_TO_SVG, {
      image: this.id,
      centerline: centerline
    }, userId);

    const resource = await ToolmakerAPIClient.instance.getResource(response.outputs.image as string, userId);
    if (!resource) {
      throw new Error('Failed to process image');
    }

    return resource;
  }

  async preSignedURL(userId: string) {
    const url = this.URL();
    const response = await ToolmakerAPIClient.instance.options!!.fetch!!(`${url.toString()}/preSignedUrl`, {
      ...ToolmakerAPIClient.instance.baseRequestInit,
      headers: {
        ...ToolmakerAPIClient.instance.baseHeaders(userId)
      },
      method: "GET",
    })

    if (!response.ok) {
      return null
    }

    return response.text()
  }
}

export {
  ImageResource
}