import {Transform} from "stream";
import {detect} from "detect-browser";

export interface FetchFunction {
  (url: string | URL, init?: RequestInit): Promise<Response>;
}

const baseHeaders = (SHARED_KEY?: string, userId?: string) => {
  const headers : any = {}

  if (userId) {
    headers['X-Fermat-User-Id'] = userId
  }

  const result = detect();
  if (!result || result.type !== 'browser') {
    if (SHARED_KEY) {
      headers['authorization'] = SHARED_KEY
    }
  }

  return headers;
}

const baseRequestInit : (init: RequestInit, SHARED_KEY?: string) => RequestInit = (init, SHARED_KEY) => {
  const result = detect();
  if (result && result.type === 'browser') {
    init.credentials = "include";
  }

  return init
}

class NodeChunkTransformStream extends Transform {
  private _remainderBuffer: any = null;

  constructor(private readonly _chunkSize: number, options?: any) {
    super(options);
  }

  _transform(chunk: any, encoding: any, callback: any) {
    let buffer = this._remainderBuffer ? Buffer.concat([this._remainderBuffer, chunk]) : chunk;

    while (buffer.length >= this._chunkSize) {
      this.push(buffer.slice(0, this._chunkSize));
      buffer = buffer.slice(this._chunkSize);
    }

    this._remainderBuffer = buffer;
    callback();
  }

  _flush(callback: any) {
    if (this._remainderBuffer && this._remainderBuffer.length > 0) {
      this.push(this._remainderBuffer);
      this._remainderBuffer = null;
    }
    callback();
  }
}

class CloudflareChunkTransformStream {
  remainderBuffer: Uint8Array | null = null;

  constructor(private readonly chunkSize: number) {}

  transform(chunk: Uint8Array, controller: TransformStreamDefaultController<Uint8Array>) {
    if (this.remainderBuffer) {
      // If there's a remainder, append the new chunk to it
      let newBuffer = new Uint8Array(this.remainderBuffer.length + chunk.length);
      newBuffer.set(this.remainderBuffer);
      newBuffer.set(chunk, this.remainderBuffer.length);
      this.remainderBuffer = newBuffer;
    } else {
      this.remainderBuffer = chunk;
    }

    while (this.remainderBuffer && this.remainderBuffer.length >= this.chunkSize) {
      controller.enqueue(new Uint8Array(this.remainderBuffer.subarray(0, this.chunkSize)));
      this.remainderBuffer = new Uint8Array(this.remainderBuffer.subarray(this.chunkSize));
    }

    if (this.remainderBuffer && this.remainderBuffer.length === 0) {
      this.remainderBuffer = null;
    }
  }

  flush(controller: TransformStreamDefaultController<Uint8Array>) {
    if (this.remainderBuffer && this.remainderBuffer.length > 0) {
      controller.enqueue(this.remainderBuffer);
      this.remainderBuffer = null;
    }
  }

  getTransformStream() {
    return new TransformStream({
      start: () => {},
      transform: this.transform.bind(this),
      flush: this.flush.bind(this),
    });
  }
}

interface MultipartStreamUploadData {
  contentType: string,
  urls: {
    startMultiPartUploadUrl: string,
    obtainMultiPartUploadUrl: string,
    putMultiPartUploadUrl?: string,
    completeMultiPartUploadUrl: string,
    abortMultiPartUploadUrl: string
  },
  callbacks: {
    onProgress?: () => void,
    onCompleted?: () => void,
    onAbort?: (err: any) => void
  }
}

export class MultipartStreamUpload {
  constructor(private data: MultipartStreamUploadData, private fetch: FetchFunction, private SHARED_KEY?: string, private userId?: string) {
  }

  startUpload = async () => {
    await this.fetch(this.data.urls.startMultiPartUploadUrl,
      baseRequestInit({
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders(this.SHARED_KEY, this.userId)
        },
        body: JSON.stringify({
          contentType: this.data.contentType,
        }),
      }, this.SHARED_KEY)
    )
  }

  uploadPart = async (partData: any, partNum: number) => {
    return new Promise<{
      ETag: string,
      PartNumber: number,
    }>(async (resolve, reject) => {
      try {
        let response: Response;
        if (this.SHARED_KEY) {
          const preSignedUploadURLResponse = await this.fetch(`${this.data.urls.obtainMultiPartUploadUrl}/${partNum}`,
            baseRequestInit({
              method: 'GET',
              headers: {
                ...baseHeaders(this.SHARED_KEY, this.userId)
              },
            }, this.SHARED_KEY)
          );

          if (!(preSignedUploadURLResponse.status === 200)) {
            throw new Error(`Error obtaining pre-signed URL for part ${partNum}`);
          }

          const data = await preSignedUploadURLResponse.json();

          response = await this.fetch(data.url, {
            method: 'PUT',
              body: partData,
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          });
        } else {
          response = await this.fetch(`${this.data.urls.putMultiPartUploadUrl}/${partNum}`, baseRequestInit({
            method: 'PUT',
            body: partData,
            headers: {
              'Content-Type': 'application/octet-stream',
              ...baseHeaders(this.SHARED_KEY, this.userId),
            },
          }, this.SHARED_KEY));
        }

        if (!(response.status === 200)) {
          throw new Error(`Error uploading part ${partNum}`);
        }

        this.data.callbacks.onProgress?.();

        resolve({
          PartNumber: partNum,
          ETag: response.headers.get('ETag') as string,
        })
      } catch (err) {
        // Consider implementing retry logic or aborting the upload
        reject(err);
      }
    })
  }

  finishUpload = async (parts: Array<{
    ETag: string,
    PartNumber: number,
  }>) => {
    await this.fetch(this.data.urls.completeMultiPartUploadUrl, baseRequestInit({
      method: 'POST',
      body: JSON.stringify({
        parts
      }),
      headers: {
        'Content-Type': 'application/json',
        ...baseHeaders(this.SHARED_KEY, this.userId),
      },
    }, this.SHARED_KEY));

    this.data.callbacks.onCompleted?.();
  }

  abortUpload = async (err: any) => {
    console.error('Error processing image:', err);
    await this.fetch(this.data.urls.abortMultiPartUploadUrl, baseRequestInit({
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        ...baseHeaders(this.SHARED_KEY, this.userId)
      },
      body: null,
    }, this.SHARED_KEY));

    this.data.callbacks.onAbort?.(err);
  }

  async createNodeStream() {
    let partNumber = 1;
    const minPartSize = 5 * 1024 * 1024; // 5MB

    const uploadStream = new NodeChunkTransformStream(minPartSize);

    const pendingUploads: Promise<any>[] = [];
    uploadStream.on('data', async (chunk) => {
      pendingUploads.push(this.uploadPart(chunk, partNumber++))
    });

    uploadStream.on('end', async () => {
      await this.finishUpload(await Promise.all(pendingUploads));
    })

    uploadStream.on('error', async (err) => {
      await this.abortUpload(err);
    })

    await this.startUpload();

    return uploadStream;
  }

  async createCFStream() {
    let partNumber = 1;
    const minPartSize = 5 * 1024 * 1024; // 5MB

    const chunkStream = new CloudflareChunkTransformStream(minPartSize);

    const pendingUploads: Promise<any>[] = [];

    await this.startUpload();

    const multipartUpload = this;
    const transform = chunkStream.getTransformStream();
    const uploadWritableStream = new WritableStream<Uint8Array>({
      async write(chunk) {
        pendingUploads.push(multipartUpload.uploadPart(chunk, partNumber++));
      },
      async close() {
        try {
          await multipartUpload.finishUpload(await Promise.all(pendingUploads));
        } catch (err) {
          await multipartUpload.abortUpload(err);
        }
      },
      async abort(err) {
        await multipartUpload.abortUpload(err);
      },
    });

    return {
      transform,
      upload: uploadWritableStream,
    }
  }
}