import {FetchFunction, MultipartStreamUpload} from './multipartStreamUpload';
import {detect} from "detect-browser";
import {Readable} from "stream";
import {contentToResourceType, extensionToResourceType} from "./utils/contentToResourceType";
import {
  castToResourceType,
  createResourceWithType,
  isTypeOrSubtype,
  Resource,
  ResourceTypes
} from "./resource/resource";
import {ImageResource} from "./resource/imageResource";
import {ObjectResource} from "./resource/objectResource";
import {ToolResource} from "./resource/toolResource";
import {JsonResource} from "./resource/jsonResource";
import {CompressedResource} from "./resource/compressedResource";
import {JobTypes} from "./job/job";
import {validate} from "uuid";

interface S3UploadTag {
  ETag: string,
  PartNumber: number
}

interface ToolmakerAPIClientOptions {
  readonly SHARED_KEY?: string;
  readonly protocol?: string;
  readonly prefix?: string;
  readonly chunkSize?: number;
  readonly fetch?: FetchFunction;
  readonly headers?: Record<string, string>;
}

interface ToolMetadata {
  name: string;
  isPublished: boolean;
  color?: string;
  logo?: string;
  description?: string;
  previewImage?: string;
  tags: string[];
  hasData: boolean;
}

class ToolmakerAPIClient {
  static instance: ToolmakerAPIClient;

  baseRequestInit: RequestInit;
  baseHeaders: (userId?: string) => Record<string, string>;

  constructor(
    public readonly host: string,
    public readonly options?: ToolmakerAPIClientOptions
  ) {
    this.options = {
      protocol: 'https:',
      prefix: '/parties/main/public',
      chunkSize: 5 * 1024 * 1024,
      ...this.options,
    }

    this.baseHeaders = (userId) => {
      const headers : Record<string, string> = (this.options?.headers) ? {...this.options?.headers} : {}

      if (userId) {
        headers['X-Fermat-User-Id'] = userId
      } else if (headers['user_id']) {
        headers['X-Fermat-User-Id'] = headers['user_id']
      }

      const result = detect();
      if (!result || result.type !== 'browser') {
        if (this.options!!.SHARED_KEY) {
          headers['authorization'] = this.options!!.SHARED_KEY
        }
      } else {
        const origin = window.location.origin;
        if (origin) {
          headers['origin'] = origin;
        }
      }

      return headers;
    }

    const result = detect()
    this.baseRequestInit = (result && result.type === 'browser') ? {
      credentials: "include"
    } : {}

    ToolmakerAPIClient.instance = this;
  }

  private chunkFile(file: Blob | File) {
    const chunks = [];
    for (let i = 0; i < file.size; i += this.options!!.chunkSize!!) {
      chunks.push(file.slice(i, i + this.options!!.chunkSize!!));
    }

    return chunks;
  }

  async createResource(type: ResourceTypes, userId?: string) {
    let response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/create/${type}`, {
      ...this.baseRequestInit,
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to create resource");
    }

    return await response.text();
  }

  async initializeResource(id: string, type: string, userId?: string) {
    let response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${id}/initialize/${type}`, {
      ...this.baseRequestInit,
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok && response.status !== 409) {
      throw new Error("Failed to initialize resource");
    }
  }

  private async* uploadChunks(resourceId: string, fileSize: number, contentType: string, chunks: Blob[], userId?: string) : AsyncGenerator<{
    progress: number,
    tag?: S3UploadTag
  }> {
    yield {
      progress: 0
    };

    const response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/upload/start`, {
      ...this.baseRequestInit,
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contentType,
        contentLength: fileSize,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to start upload");
    }

    // Upload each chunk
    for (const [index, chunk] of chunks.entries()) {
      if (this.options!!.SHARED_KEY === undefined) {
        const response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/upload/part/${index + 1}`, {
          ...this.baseRequestInit,
          headers: {
            ...this.baseHeaders(userId),
          },
          method: "PUT",
          body: chunk,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload chunk ${index + 1}`);
        }

        const ETag = response.headers.get("ETag");
        if (!ETag) {
          throw new Error(`Failed to get ETag for chunk ${index + 1}`);
        }

        yield {
          progress: (index + 1) / chunks.length,
          tag: {
            ETag,
            PartNumber: index + 1,
          }
        }
      } else {
        // Obtain S3 pre-signed URL
        let response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/upload/part/${index + 1}`, {
          ...this.baseRequestInit,
          headers: {
            ...this.baseHeaders(userId),
          },
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`Failed to upload chunk ${index + 1}`);
        }

        const {
          url: preSignedURL,
        } = await response.json();

        response = await this.options!!.fetch!!(preSignedURL, {
          method: "PUT",
          body: chunk
        })

        if (!response.ok) {
          throw new Error(`Failed to upload chunk ${index + 1}`);
        }

        const ETag = response.headers.get("ETag");
        if (!ETag) {
          throw new Error(`Failed to get ETag for chunk ${index + 1}`);
        }

        yield {
          progress: (index + 1) / chunks.length,
          tag: {
            ETag,
            PartNumber: index + 1,
          }
        }
      }
    }
  }

  private async completeUpload(resourceId: string, ETags: S3UploadTag[], userId?: string) {
    // Complete the upload
    const response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/upload/complete`, {
      ...this.baseRequestInit,
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        parts: ETags,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to complete upload");
    }
  }

  public async uploadObjectResource(
    file: Blob | File | Readable | ReadableStream | string | URL,
    resourceId?: string,
    contentType?: string,
    allocateCallback?: (resourceId: string) => void,
    progressCallback?: (percentage: number) => void,
    userId?: string
  ): Promise<Resource | null> {
    if (file instanceof File && !contentType) {
      contentType = file.type;
    } else if (typeof file === "string" || file instanceof URL) {} else if (!contentType) {
      throw new Error('contentType is required for Blob, Readable and ReadableStream uploads');
    }

    if (!resourceId && !contentType && typeof file !== "string" && !(file instanceof URL)) {
      throw new Error('resourceId or contentType is required');
    }

    if (!resourceId && typeof file !== "string" && !(file instanceof URL)) {
      const resourceType = contentToResourceType[contentType!!];
      if (!resourceType) throw new Error(`Unsupported content type: ${contentType}`);

      resourceId = await this.createResource(resourceType, userId)
    }

    allocateCallback?.(resourceId!!);

    if (typeof file === 'string' || file instanceof URL) {
      if (file instanceof URL) {
        file = file.toString();
      } else {
        try {
          const url = new URL(file);
          file = url.toString();
        } catch (e) {
          if (validate(file)) {
            const resource = await this.getResource(file, userId)
            if (resource) {
              return resource
            }
          }

          throw new Error("Invalid URL");
        }
      }

      const createFromURLEndpoint = `${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/createFromURL/`
      const response = await this.options!!.fetch!!(createFromURLEndpoint, {
        ...this.baseRequestInit,
        method: "POST",
        headers: {
          ...this.baseHeaders(userId),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: file,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to upload resource from URL: ${response.status} - ${await response.text()} - ${file} - ${createFromURLEndpoint}`);
      }

      resourceId = await response.text();

      progressCallback?.(1);
    } else if (file instanceof Readable || file instanceof ReadableStream) {
      const response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/endpoints`, {
        ...this.baseRequestInit,
        headers: {
          ...this.baseHeaders(userId),
        }
      });

      if (!response.ok) {
        throw new Error("Failed to get upload endpoints");
      }

      const endpoints = await response.json();

      const multipartStreamUpload = new MultipartStreamUpload({
        contentType: contentType!!,
        urls: {
          startMultiPartUploadUrl: endpoints.startMultiPartUploadUrl,
          obtainMultiPartUploadUrl: endpoints.obtainMultiPartUploadUrl,
          putMultiPartUploadUrl: endpoints.putMultiPartUploadUrl,
          completeMultiPartUploadUrl: endpoints.completeMultiPartUploadUrl,
          abortMultiPartUploadUrl: endpoints.abortMultiPartUploadUrl,
        },
        callbacks: {}
      }, this.options!!.fetch!!, this.options!!.SHARED_KEY, userId)

      if (file instanceof Readable) {
        const stream = await multipartStreamUpload.createNodeStream();
        file.pipe(stream);
      } else {
        const streams = await multipartStreamUpload.createCFStream();
        await file.pipeThrough(streams.transform).pipeTo(streams.upload);
      }

      progressCallback?.(1);
    } else if (file instanceof Blob && file.size > this.options!!.chunkSize!!) {
      const chunks = this.chunkFile(file);
      const uploadGenerator = this.uploadChunks(resourceId!!, file.size, contentType!!, chunks, userId)

      const parts: S3UploadTag[] = [];
      for await (const {progress, tag} of uploadGenerator) {
        if (tag) {
          parts.push(tag);
        }

        progressCallback?.(progress);
      }

      await this.completeUpload(resourceId!!, parts, userId);
    } else {
      const response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/upload`, {
        ...this.baseRequestInit,
        method: "PUT",
        body: file,
        headers: {
          ...this.baseHeaders(userId),
          "Content-Type": contentType!!,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to upload resource: ${response.status} ${await response.text()}`);
      }

      progressCallback?.(1);
    }

    return this.getResource(resourceId!!, userId);
  }

  public async getResource(id: string, userId?: string) : Promise<Resource | null> {
    if (!validate) {
      return null
    }

    const url = `${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${id}/type`;
    const response = await this.options!!.fetch!!(url, {
      ...this.baseRequestInit,
      headers: {
        ...this.baseHeaders(userId),
      }
    })

    if (!response.ok) {
      if (response.status === 404) return null
      throw new Error(`Failed to get resource type: ${response.status} ${await response.text()} ${url}`);
    }

    const type = castToResourceType(await response.text());

    if (!type) {
      throw new Error("Invalid resource type")
    }

    return createResourceWithType(id, type);
  }

  public async searchResources(query: {
    id?: string
    type?: string,
    owner?: string,
    date_created?: {
      gte?: number,
      lte?: number
    },
    date_updated?: {
      gte?: number,
      lte?: number
    },
    page?: number,
    page_size?: number,
    sort_by?: 'date_created' | 'date_updated',
    sort_order?: 'asc' | 'desc'
  }, userId: string) {
    const url = new URL(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/search/`)

    const response = await this.options!!.fetch!!(url.toString(), {
      ...this.baseRequestInit,
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query)
    })

    if (!response.ok) {
      throw new Error(`Failed to query resources: ${response.status} ${await response.text()} ${url}`);
    }

    return await response.json()
  }

  getResourceURL(id: string) {
    return `${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${id}`;
  }

  getVariantURL(id: string, variant: string) {
    return `${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${id}/variant/${variant}`;
  }

  async getVariantId(id: string, variant: string, userId?: string) {
    const url = this.getVariantURL(id, variant);
    const response = await this.options!!.fetch!!(url, {
      ...this.baseRequestInit,
      method: "HEAD",
      headers: {
        ...this.baseHeaders(userId),
      }
    })

    if (!response.ok) {
      throw new Error("Failed to get variant ID");
    }

    return response.headers.get("X-Fermat-Resource-Id")!!;
  }

  async uploadToolResource(
    tool: {
      slides?: any,
      metadata?: any
    },
    resourceId?: string,
    userId?: string
  ) {
    if (!resourceId) {
      resourceId = await this.createResource(ResourceTypes.TOOL, userId);
    }

    let response: Response;
    if (tool.slides && !tool.metadata) {
      response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/data`, {
        ...this.baseRequestInit,
        method: "POST",
        headers: {
          ...this.baseHeaders(userId),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tool.slides),
      });
    } else if (!tool.slides && tool.metadata) {
      response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/metadata`, {
        ...this.baseRequestInit,
        method: "POST",
        headers: {
          ...this.baseHeaders(userId),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tool.metadata),
      });
    } else if (tool.slides && tool.metadata) {
      response = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/resource/${resourceId}/`, {
        ...this.baseRequestInit,
        method: "POST",
        headers: {
          ...this.baseHeaders(userId),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: tool.slides,
          metadata: tool.metadata,
        }),
      });
    } else {
      throw new Error("Invalid tool data");
    }

    if (!response.ok) {
      throw new Error("Failed to upload tool resource");
    }

    return this.getResource(resourceId, userId);
  }

  public async createJob(type: JobTypes, parameters: any, userId?: string) {
    const jobResponse = await this.options!!.fetch!!(`${this.options!!.protocol!!}//${this.host}${this.options!!.prefix!!}/job/create/${type}/`, {
      method: "POST",
      headers: {
        ...this.baseHeaders(userId),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parameters)
    })

    if (!jobResponse.ok) throw new Error("Failed to create job")

    return await jobResponse.json()
  }
}

export {
  ToolmakerAPIClient,
  ToolMetadata,
  MultipartStreamUpload,
  contentToResourceType,
  extensionToResourceType,
  Resource,
  ResourceTypes,
  isTypeOrSubtype,
  ImageResource,
  ObjectResource,
  JsonResource,
  ToolResource,
  CompressedResource,
  JobTypes,
  createResourceWithType,
  castToResourceType,
  FetchFunction
}