import {Resource, ResourceTypes} from './resource';

class ObjectResource extends Resource {
  constructor(readonly id: string, readonly type: ResourceTypes.OBJECT | ResourceTypes.COMPRESSED | ResourceTypes.IMAGE = ResourceTypes.OBJECT) {
    super(id, type);
  }
}

export {
  ObjectResource
}