import {Resource, ResourceTypes} from './resource';
import {ToolmakerAPIClient} from "../index";

class JsonResource extends Resource {
    constructor(readonly id: string, readonly type: ResourceTypes.JSON = ResourceTypes.JSON) {
        super(id, type);
    }

    async update(data: any, userId: string) {
        const url = this.URL()
        const response = await ToolmakerAPIClient.instance.options!!.fetch!!(url.toString(), {
            ...ToolmakerAPIClient.instance.baseRequestInit,
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                ...ToolmakerAPIClient.instance.baseHeaders(userId),
                "Content-Type": "application/json"
            }
        })

        if (!response.ok) {
            throw new Error("Failed to update resource")
        }

        return await response.json()
    }
}

export {
    JsonResource
}