import "./styles.css";
import {ImageResource, JobTypes, ToolmakerAPIClient} from "@oca.computer/toolmaker-api-client";
import {validate} from "uuid";

declare const PARTYKIT_HOST: string;

const client = new ToolmakerAPIClient(PARTYKIT_HOST, {
  protocol: window.location.protocol,
  fetch: window.fetch.bind(window),
});

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(content: string | Element) {
  if (typeof content === "string") {
    output.appendChild(document.createTextNode(content));
    output.appendChild(document.createElement("br"));
  } else {
    output.appendChild(content);
  }
}

async function identify() : Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    const protocol = window.location.protocol;
    let path = `/parties/main/public/identify`;
    const response = await fetch(`${protocol}//${PARTYKIT_HOST}${path}`, {
      method: "GET",
    });

    if (response.ok) {
      add("Identified");
      resolve(true);
    } else {
      add("Failed to identify");
      const keyInput = document.createElement("input");
      keyInput.type = "password";

      const button = document.createElement("button");
      button.textContent = "Submit";

      button.addEventListener("click", async () => {
        const key = keyInput.value;
        const protocol = window.location.protocol;
        let path = `/parties/main/public/login`;
        const response = await fetch(`${protocol}//${PARTYKIT_HOST}${path}`, {
          method: "POST",
          body: JSON.stringify({key}),
        });

        if (response.ok) {
          add("Logged in");
          resolve(true);
        } else {
          add("Failed to log in, try again");
        }
      });

      add(keyInput);
      add(button);
    }
  });
}

const protocol = window.location.protocol;

identify().then((result) => {
  const logout = document.createElement("button");
  logout.textContent = "Logout";

  logout.addEventListener("click", async () => {
    const protocol = window.location.protocol;
    let path = `/parties/main/public/logout`;
    const response = await fetch(`${protocol}//${PARTYKIT_HOST}${path}`, {
      method: "POST",
    });

    if (response.ok) {
      add("Logged out");
    } else {
      add("Failed to log out");
    }
  });

  add(logout);

  const urlInput = document.createElement("input");
  urlInput.type = "text";
  urlInput.placeholder = "Enter file URL";
  add(urlInput)

  urlInput.addEventListener("keydown", async (event) => {
    if (event.key !== "Enter") {
      return;
    }

    const url = urlInput.value;
    urlInput.value = "";
    const resource = await client.uploadObjectResource(
        url,
        undefined,
        undefined,
        undefined,
        undefined,
        "Toolmaker-Test-Client"
    )

    if (!resource) {
      add("Failed to upload resource");
      return;
    }

    const img = document.createElement("img");
    img.src = resource.URL().toString();
    add(img);
  })

  // Create file input element
  const input = document.createElement("input");
  input.type = "file";

  // Append input to the DOM
  add(input);

  // When the input changes
  input.addEventListener("change", async () => {
    // Get the file
    const file = input.files?.[0];
    if (!file) {
      return;
    }

    const contentType = file.type;

    const complete = client.uploadObjectResource(file, undefined, contentType, (resourceId) => {
      add(`Obtained resource ID: ${resourceId}`);
    }, (progress) => {
      add(`Progress: ${Math.round(progress * 100)}%`);
    }, "Toolmaker-Test-Client")

    complete.then(async (resource) => {
      if (!resource) {
        add("Failed to upload resource");
        return;
      }

      let row = document.createElement("div");
      row.style.display = "flex";
      const highRes = document.createElement("img");
      highRes.src = resource.URL().toString();
      row.appendChild(highRes);
      add(row);

      /*const metadata = document.createElement("div");
      metadata.innerText = JSON.stringify(await resource.metadata("Toolmaker-Test-Client"), null, 2);
      add(metadata);

      const lowRes = document.createElement("img");
      lowRes.src = resource.variantURL("low").toString();
      add(lowRes);

      const resized = await (resource as ImageResource).resize({
        width: 32,
      }).process("Toolmaker-Test-Client")

      if (!resized) {
        add("Failed to resize");
        return;
      }

      const resizedImg = document.createElement("img");
      resizedImg.src = resized.URL().toString();
      add(resizedImg);*/

      const job = await ToolmakerAPIClient.instance.createJob(JobTypes.PROCESS_IMAGE, {
        "image": resource.id,
        "process": [
          {
            type: "format",
            parameters: {
              format: "webp"
            }
          }
        ]
      }, "Toolmaker-Test-Client");

      const webpImageResource = new ImageResource(job.outputs.image)
      const webpImage = document.createElement("img");
      webpImage.src = webpImageResource.URL().toString();
      add(webpImage);
    })
  });

  const toolJSONInput = document.createElement("textarea");
  toolJSONInput.placeholder = "Enter tool JSON";
  add(toolJSONInput);

  const toolJSONPrettifyButton = document.createElement("button");
  toolJSONPrettifyButton.textContent = "Prettify";
  toolJSONPrettifyButton.addEventListener("click", () => {
    toolJSONInput.value = JSON.stringify(JSON.parse(toolJSONInput.value), null, 2);
  });
  add(toolJSONPrettifyButton);

  const toolIdInput = document.createElement("input");
  toolIdInput.type = "text";
  toolIdInput.placeholder = "Enter tool ID";
  add(toolIdInput);

  const toolCreateButton = document.createElement("button");
  toolCreateButton.textContent = "Create Tool";
  toolCreateButton.addEventListener("click", async () => {
    const toolJSON = JSON.parse(toolJSONInput.value);
    const resource = await client.uploadToolResource(toolJSON, undefined, "Toolmaker-Test-Client")
    if (!resource) {
      add("Failed to create tool")
      return;
    }
    add(`Created tool: ${resource.id}`);
    const url = resource.URL().toString();
    const response = await fetch(url);
    if (!response.ok) {
      add("Failed to fetch resource");
      return;
    }
    const data = await response.json();
    // add(`Fetched resource: ${JSON.stringify(data, null, 2)}`);

    const metadata = await resource.metadata("Toolmaker-Test-Client");
    add(`Metadata: ${JSON.stringify(metadata, null, 2)}`);
  });
  add(toolCreateButton);

  const toolUpdateButton = document.createElement("button");
  toolUpdateButton.textContent = "Update Tool";
  toolUpdateButton.addEventListener("click", async () => {
    const toolJSON = JSON.parse(toolJSONInput.value);
    const toolId = toolIdInput.value;
    if (!validate(toolId)) {
      add("Invalid tool ID");
      return;
    }
    const tool = await client.uploadToolResource(toolJSON, toolId, "Toolmaker-Test-Client")
    add(`Updated tool: ${tool}`);
  });
  add(toolUpdateButton);

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
  /*const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: "public",
    query: async () => ({
      // get an auth token using your authentication client library
      token: uuidv4(),
    })
  });

  // You can even start sending messages before the connection is open!
  conn.addEventListener("message", (event) => {
    add(`Received -> ${event.data}`);
  });*/
})