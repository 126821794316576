import {Resource, ResourceTypes} from './resource';
import {ToolmakerAPIClient} from "../index";

class CompressedResource extends Resource {
    constructor(readonly id: string, readonly type: ResourceTypes.COMPRESSED = ResourceTypes.COMPRESSED) {
        super(id, type);
    }

    async preSignedURL(userId: string) {
        const url = this.URL();
        const response = await ToolmakerAPIClient.instance.options!!.fetch!!(`${url.toString()}/preSignedUrl`, {
            ...ToolmakerAPIClient.instance.baseRequestInit,
            headers: {
                ...ToolmakerAPIClient.instance.baseHeaders(userId)
            },
            method: "GET",
        })

        if (!response.ok) {
            return null
        }

        return response.text()
    }
}

export {
    CompressedResource
}