import {ResourceTypes} from "../resource/resource";

export const contentToResourceType : Record<string, ResourceTypes> = {
  'image/apng': ResourceTypes.IMAGE,
  'image/avif': ResourceTypes.IMAGE,
  'image/bmp': ResourceTypes.IMAGE,
  'image/gif': ResourceTypes.IMAGE,
  'image/vnd.microsoft.icon': ResourceTypes.IMAGE,
  'image/jpeg': ResourceTypes.IMAGE,
  'image/png': ResourceTypes.IMAGE,
  'image/svg+xml': ResourceTypes.IMAGE,
  'image/tiff': ResourceTypes.IMAGE,
  'image/webp': ResourceTypes.IMAGE,
  'image/heic': ResourceTypes.IMAGE,
  'text/plain': ResourceTypes.TEXT,
  'text/csv': ResourceTypes.TEXT,
  'application/json': ResourceTypes.JSON,
  'application/pdf': ResourceTypes.PDF,
  'application/zip': ResourceTypes.COMPRESSED,
  'application/vnd.rar': ResourceTypes.COMPRESSED,
  'application/x-7z-compressed': ResourceTypes.COMPRESSED,
  'application/x-tar': ResourceTypes.COMPRESSED,
  'application/gzip': ResourceTypes.COMPRESSED,
  'application/x-gzip': ResourceTypes.COMPRESSED,
}

export const extensionToResourceType : Record<string, ResourceTypes> = {
  'png': ResourceTypes.IMAGE,
  'jpeg': ResourceTypes.IMAGE,
  'jpg': ResourceTypes.IMAGE,
  'webp': ResourceTypes.IMAGE,
  'svg': ResourceTypes.IMAGE,
  'gif': ResourceTypes.IMAGE,
  'bmp': ResourceTypes.IMAGE,
  'tiff': ResourceTypes.IMAGE,
  'heic': ResourceTypes.IMAGE,
  'pdf': ResourceTypes.PDF,
  'zip': ResourceTypes.COMPRESSED,
  'rar': ResourceTypes.COMPRESSED,
  '7z': ResourceTypes.COMPRESSED,
  'tar': ResourceTypes.COMPRESSED,
  'gzip': ResourceTypes.COMPRESSED,
  'gz': ResourceTypes.COMPRESSED,
}