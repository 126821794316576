import {ImageResource, ObjectResource, ToolResource, ToolmakerAPIClient, JsonResource, CompressedResource} from "../index";

enum ResourceTypes {
  DEFAULT = "default",
  OBJECT = "object",
  IMAGE = "image",
  COMPRESSED = "compressed",
  TOOL = "tool",
  JSON = "json",
  TEXT = "text",
  PDF = "pdf",
  LORA_STYLE = "loraStyle",
  USER = "tmkrUser",
}

function stringIsResourceType(value: string): value is ResourceTypes {
  return Object.values(ResourceTypes).includes(value as ResourceTypes);
}

function castToResourceType(value: string): ResourceTypes | undefined {
  if (stringIsResourceType(value)) {
    return value;
  }
  return undefined;
}

const TypeHierarchy : { [key in ResourceTypes]?: ResourceTypes[] } = {
  [ResourceTypes.OBJECT]: [ResourceTypes.IMAGE, ResourceTypes.COMPRESSED, ResourceTypes.PDF, ResourceTypes.TEXT],
  [ResourceTypes.JSON]: [ResourceTypes.LORA_STYLE]
};

function isTypeOrSubtype(type: ResourceTypes, targetType: ResourceTypes): boolean {
  if (type === targetType) {
    return true;
  }
  return TypeHierarchy[targetType]?.includes(type) || false;
}

export function createResourceWithType(id: string, type: ResourceTypes) {
  let resource: Resource;
  switch (type) {
    case ResourceTypes.IMAGE:
      resource = new ImageResource(id, type);
      break;
    case ResourceTypes.OBJECT:
      resource = new ObjectResource(id, type);
      break;
    case ResourceTypes.TOOL:
      resource = new ToolResource(id, type);
      break;
    case ResourceTypes.JSON:
      resource = new JsonResource(id, type);
      break;
    case ResourceTypes.COMPRESSED:
      resource = new CompressedResource(id, type);
      break;
    case ResourceTypes.DEFAULT:
    default:
      resource = new Resource(id, type);
      break;
  }

  return resource;
}

class Resource {
  constructor(public id: string, public type: ResourceTypes) {
  }

  URL() {
    return new URL(`${ToolmakerAPIClient.instance.options!!.protocol!!}//${ToolmakerAPIClient.instance.host}${ToolmakerAPIClient.instance.options!!.prefix!!}/resource/${this.id}`);
  }

  variantURL(variant: string) {
    return new URL(`${ToolmakerAPIClient.instance.options!!.protocol!!}//${ToolmakerAPIClient.instance.host}${ToolmakerAPIClient.instance.options!!.prefix!!}/resource/${this.id}/variant/${variant}`);
  }

  async variant(variant: string, userId?: string) {
    const url = this.variantURL(variant);
    const response = await ToolmakerAPIClient.instance.options!!.fetch!!(url.toString(), {
      ...ToolmakerAPIClient.instance.baseRequestInit,
      headers: {
        ...ToolmakerAPIClient.instance.baseHeaders(userId)
      },
      method: "HEAD",
    })

    if (!response.ok) {
      return null
    }

    const variantID = response.headers.get("X-Fermat-Resource-Id")!!;
    const variantType = castToResourceType(response.headers.get("X-Fermat-Resource-Type")!!);

    if (!variantType) {
      return null
    }

    return new Resource(variantID, variantType)
  }

  async metadata(userId?: string) {
    const url = `${ToolmakerAPIClient.instance.options!!.protocol!!}//${ToolmakerAPIClient.instance.host}${ToolmakerAPIClient.instance.options!!.prefix!!}/resource/${this.id}/metadata`;
    const response = await ToolmakerAPIClient.instance.options!!.fetch!!(url, {
      ...ToolmakerAPIClient.instance.baseRequestInit,
      headers: {
        ...ToolmakerAPIClient.instance.baseHeaders(userId)
      }
    });

    if (!response.ok) {
      throw new Error("Failed to get resource metadata");
    }

    return await response.json();
  }

  toJSON() {
    return {
      id: this.id,
      type: this.type
    }
  }
}

export {
  Resource,
  ResourceTypes,
  isTypeOrSubtype,
  castToResourceType
}