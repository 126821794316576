export enum JobTypes {
  DUMMY = "dummy",
  PROCESS_IMAGE = "processImage",
  NORMALIZE_IMAGE = "normalizeImage",
  OPTIMIZE_IMAGE = "optimizeImage",
  LOW_RESOLUTION_IMAGE = "lowResolutionImage",
  RESIZE_IMAGE = "resizeImage",
  BINARIZE_IMAGE = "binarizeImage",
  GREYSCALE_IMAGE = "greyscaleImage",
  CONVERT_IMAGE_TO_SVG = "convertImageToSVG",
  EXTRACT_FACE_MASK_FROM_IMAGE = "extractFaceMaskFromImage",
  MERGE_IMAGE_WITH_ALPHA = "mergeImageWithAlpha",
  COMPRESS_OBJECTS = "compressObjects",
  EXTRACT_COLOR_PALETTE = "extractColorPalette",
  TEXTURE_PATCHES = "texturePatches",
  APPLY_TEXTURE = "applyTexture",
  COMPOSITE_IMAGE = "compositeImage",
  HEX_TO_PANTONE = "hexToPantone",
  FORMAT_IMAGE = "formatImage",
}