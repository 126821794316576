import {Resource, ResourceTypes} from './resource';

class ToolResource extends Resource {
    constructor(readonly id: string, readonly type: ResourceTypes.TOOL = ResourceTypes.TOOL) {
        super(id, type);
    }
}

export {
    ToolResource
}